import React from 'react'
import Milestone from './Milestone'

function MilestoneEdit({edit ,reload}) {
  return (
    <div className='my-3'>
        <Milestone milestone={edit} reload={reload} editMode={true}/>
    </div>
  )
}

export default MilestoneEdit