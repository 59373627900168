import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Swal from "sweetalert2";
import axios from "axios";
import { dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneActions({ mode, milestone, reload }) {
  const [marking, setMarking] = useState(false);
  const approve = () => {
    Swal.fire({
      icon: "warning",
      title: "Approve this milestone?",
      text: "Be sure to review all of the details of this milestone before proceeding. This will reject any other versions of this milestone you have approved in the past.",
      showCancelButton: true,
      confirmButtonText: "Approve",
    }).then((answer) => {
      if (answer.isConfirmed) {
        axios
          .post("/api/milestone/approval", {
            milestone_id: milestone.m_id,
            approve: true,
          })
          .then((res) => {
            reload();
            Swal.fire({
              icon: "success",
              title: "You approved this milestone.",
              timer: 2000,
            });
          });
      }
    });
  };
  const reject = () => {
    Swal.fire({
      icon: "warning",
      title: "Reject this milestone?",
      text: "Be sure to submit an edit with the changes you wish to make.",
      showCancelButton: true,
      confirmButtonText: "Reject",
    }).then((answer) => {
      if (answer.isConfirmed) {
        axios
          .post("/api/milestone/approval", {
            milestone_id: milestone.m_id,
            reject: true,
          })
          .then((res) => {
            reload();
            Swal.fire({
              icon: "success",
              title: "You rejected this milestone.",
              timer: 2000,
            });
          });
      }
    });
  };
  const startDeposit = () => {
    Swal.fire({
      icon: "question",
      title: `Deposit ${dollarFormat(milestone.deposit_total)}?`,
      text: "We will start an ACH withdrawal with the account you set up at the beginning of this project. This amount reflects the amount that will be disbursed to the contractor(s) as well as the project fee. ",
      showCancelButton: true,
      confirmButtonText: `Yes, deposit ${dollarFormat(
        milestone.deposit_total
      )}.`,
    }).then((answer) => {
      if (answer.isConfirmed) {
        axios
          .post("/api/milestone/deposit", { milestone_id: milestone.m_id })
          .then(() => {
            reload();
            Swal.fire({
              icon: "success",
              title: "Deposit request submitted!",
              text: "The project can now start! Please allow up to 5 business days to see the withdrawal take effect.",
              timer: 10000,
            });
          })
          .catch((err) => {
            console.log("err", err);
            Swal.fire({
              icon: "error",
              title: "Error starting deposit.",
              text: err,
            });
          });
      }
    });
  };
  const markComplete = (complete) => {
    Swal.fire({
      icon: "question",
      title: "Mark this milestone complete?",
      text: "This will notify everyone that the milestone is completed as defined according to you.",
      showCancelButton: true,
      confirmButtonText: "Mark Complete",
    })
      .then((decision) => {
        if (decision.isConfirmed) {
          setMarking(true);
          axios
            .put("/api/milestone/" + milestone.m_id + "/complete", { complete })
            .then((res) => {
              reload();
              Swal.fire({
                icon: "success",
                title: "Marked Complete!",
                text: "Thank you for marking this milestone complete.",
                timer: 5000,
              });
              setMarking(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error marking complete.",
          text: "Please reload and try again.",
          timer: 10000,
        });
      });
  };
  return (
    <Container className="p-3 mb-3 bg-light-grey rounded-3">
      <h3>Your Actions</h3>

      {milestone.rejected_count > 0 ? (
        <span>
          This milestone has been rejected. Please check for edits pending
          approval or submit your own.
        </span>
      ) : (
        milestone.user_approval_status === "pending" && (
          <Container className=" mb-5 d-flex flex-column align-items-center p-3 bg-light rounded-3">
            <span className="d-block text-center">
              Please approve or reject this milestone after verifying the
              details below.
            </span>
            <div>
              <Button onClick={() => approve()} className="btn-sm">
                Approve
              </Button>
              <Button
                className="ms-3 btn-sm"
                variant="danger"
                onClick={() => reject()}
              >
                Reject
              </Button>
            </div>
          </Container>
        )
      )}
      {milestone.rejected_count < 1 && (
        <Row>
          {milestone.user_is_customer ? (
            milestone.not_approved_count < 1 && (
              <>
                {milestone.deposit_count < 1 ? (
                  <Col>
                    <Button
                      variant="success"
                      className="btn-sm"
                      onClick={() => startDeposit()}
                    >
                      Deposit Funds
                    </Button>
                  </Col>
                ) : (
                  <>
                    <Col>
                      <em className="text-success">Deposit Submitted.</em>
                    </Col>
                    <Col>
                      {!milestone.user_marked_complete ? (
                        <>
                          <Button
                            className="btn-sm mb-2"
                            variant="primary"
                            disabled={marking}
                            onClick={() => markComplete(true)}
                          >
                            {marking
                              ? "Marking Complete..."
                              : "Mark Milestone Complete"}
                          </Button>
                          {milestone.complete_count > 0 ? (
                            milestone.user_is_customer ? (
                              <em className="d-block text-primary">
                                {milestone.primary_company_name} marked this
                                milestone complete.
                              </em>
                            ) : (
                              <em className="d-block text-primary">
                                {milestone.customer_name} marked this milestone
                                complete.
                              </em>
                            )
                          ) : (
                            <em className="d-block text-primary">
                              No one has marked this milestone complete.
                            </em>
                          )}
                        </>
                      ) : milestone.incomplete_count > 0 ? (
                        <Col>
                          <em className="d-block text-primary">
                            You marked this milestone complete.
                          </em>

                          {milestone.user_is_customer ? (
                            <em className="d-block text-secondary">
                              {milestone.primary_company_name} still needs to
                              complete this milestone.
                            </em>
                          ) : (
                            <em className="d-block text-secondary">
                              {milestone.customer_name} still needs to mark this
                              milestone complete.{" "}
                            </em>
                          )}
                        </Col>
                      ) : (
                        <Col>
                          <em className="text-primary">
                            This milestone is complete according to both
                            parties.
                          </em>
                        </Col>
                      )}
                    </Col>
                  </>
                )}
              </>
            )
          ) : (
            <>
              {milestone.deposit_count < 1 ? (
                <Col>
                  <Button
                    variant="secondary"
                    className="btn-sm"
                    // onClick={() => startDeposit()}
                  >
                    Wait for Deposit
                  </Button>
                </Col>
              ) : (
                <Col>
                  <em className="text-success">Deposit Submitted.</em>
                </Col>
              )}
              <Col>
                {!milestone.user_marked_complete && (
                  <>
                    <Col>
                      {!milestone.user_marked_complete ? (
                        <>
                          <Button
                            className="btn-sm mb-2"
                            variant="primary"
                            disabled={marking || milestone.deposit_count < 1}
                            onClick={() => markComplete(true)}
                          >
                            {marking
                              ? "Marking Complete..."
                              : "Mark Milestone Complete"}
                          </Button>
                          {milestone.complete_count > 0 ? (
                            milestone.user_is_customer ? (
                              <em className="d-block text-primary">
                                {milestone.primary_company_name} marked this
                                milestone complete.
                              </em>
                            ) : (
                              <em className="d-block text-primary">
                                {milestone.customer_name} marked this milestone
                                complete.
                              </em>
                            )
                          ) : milestone.not_approved_count > 0 ? (
                            <em className="d-block text-secondary">
                              This milestone needs approval first.
                            </em>
                          ) : milestone.deposit_count < 1 ? (
                            <em className="d-block text-secondary">
                              This milestone needs a deposit first.
                            </em>
                          ) : (
                            <em className="d-block text-primary">
                              No one has marked this milestone complete.
                            </em>
                          )}
                        </>
                      ) : milestone.incomplete_count > 0 ? (
                        <Col>
                          <em className="d-block text-primary">
                            You marked this milestone complete.
                          </em>

                          {milestone.user_is_customer ? (
                            <em className="d-block text-secondary">
                              {milestone.primary_company_name} still needs to
                              complete this milestone.
                            </em>
                          ) : (
                            <em className="d-block text-secondary">
                              {milestone.customer_name} still needs to mark this
                              milestone complete.{" "}
                            </em>
                          )}
                        </Col>
                      ) : (
                        <Col>
                          <em className="text-primary">
                            This milestone is complete according to both
                            parties.
                          </em>
                        </Col>
                      )}
                    </Col>
                  </>
                )}
              </Col>
            </>
          )}
        </Row>
      )}
    </Container>
  );
}

export default MilestoneActions;
