import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import MilestoneDisbursement from "./MilestoneDisbursement";
import { dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneDisbursements({ milestone }) {
  const [loaded, setLoaded] = useState(false);
  const [disbursements, setDisbursements] = useState([])
  const loadData = () => {
    if(loaded){
        setLoaded(false)
    }
    axios.get("/api/milestone/" + milestone?.m_id + "/disbursements").then((res) => {
        setDisbursements(res.data)
        setLoaded(true)
    })
  };
  useEffect(() => {
    loadData()
  }, [milestone?.m_id]);
  return loaded ? (
    <ListGroup className='mb-3'>
      <h3>Recorded Disbursements</h3>
      <h4>{dollarFormat(milestone.total_disbursed)}/{dollarFormat(milestone.total_to_disburse)}</h4>
      {loaded ? (
        disbursements?.length > 0 ? (
          disbursements.map((disbursement) => {
            return <MilestoneDisbursement key={disbursement.md_id} disbursement={disbursement} />;
          })
        ) : (
          <em>No disbursements have been sent yet. Be sure the milestone is marked complete by both parties.</em>
        )
      ) : (
        <Container className=" d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
    </ListGroup>
  ) : (
    <Container className=" d-flex justify-content-center">
                <Spinner animation="grow" />
              </Container>
  )
}

export default MilestoneDisbursements;
