import React from "react";
import "./Sponsored.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function Sponsored() {
  return (
    <Container>
      {/* <h2 id="sponsored-header" className="text-primary text-center">
        WE PROMISE
      </h2> */}
      <Container className="">
        <Row className="mb-3">
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center mb-3">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/communicate.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                Provide a platform that makes project owner and contractor
                collaboration easy.
              </Card.Title>
              <Card.Text>
                Communication is key when planning and executing a project. We
                will faciliate communication between project owners and
                contractors so the projects go smoothly.
              </Card.Text>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/thumbsUp.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                Provide piece of mind for project owners and contractors.
              </Card.Title>
              <Card.Text>
                Knowing that the finances are being taken care of by a trusted
                third party will provide the confidence you need when starting a
                new project. Project owners and contractors can confidently work
                together, even if there is no history between them.
              </Card.Text>
            </Card>
          </Col>
        </Row>
        {/* <Row className="mb-3">
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center mb-3">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/location.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                ...Have a Physical Address
              </Card.Title>
              <Card.Text>
                It is crucial for a our sponsored companies to have a legitmate business address and contact information. We want you to feel confident in the companies we sponsor. 
              </Card.Text>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/pricing.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                ...Offer Competitive Pricing
              </Card.Title>
              <Card.Text>
                We want everyone to be treated fairly and we sponsor companies that believe the same. You can expect to get competitive pricing for the high quality work you will receive.
              </Card.Text>
            </Card>
          </Col>
        </Row> */}
        <Row className="mb-3">
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center mb-3">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/licensed.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                Provide a secure payment processing system.
              </Card.Title>
              <Card.Text>
                We will collect funds at the beginning of the project and
                release them at predetermined milestones. This way both project
                owners and contractors know that their payments are protected
                and distributed in a timeley manner.
              </Card.Text>
            </Card>
          </Col>
          <Col xs="12" md="6">
            <Card className="rounded-3 d-flex flex-column align-items-center p-3 justify-content-center text-center">
              <img
                className="sponsored-logo"
                src="/images/blue-logos/star.jpg"
                alt=""
              />
              <Card.Title className="text-primary">
                Provide technical support or mediation if required.
              </Card.Title>
              <Card.Text>
                In the case of the need of technical support, we will assist you
                promptly. We also provide mediation if there are disagreements
                between a project owner, contractor, or subcontractor.
              </Card.Text>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Sponsored;
