import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import { dollarFormat } from "../../../../assets/commonFunctions";
import { useEffect } from "react";
function PayeeForm({
  payee,
  singleMode,
  parentState,
  setParentState,
  validated,
  index,
}) {
  //todo get data about how much has been decided in other milestones.
  //  fixed, percent, or remainder
  const [state, setState] = useState({
    percentage: parseFloat(payee.percent_decimal) * 100 || "",
    amount: payee.amount || "",
    type: payee.type || "fixed",
  });
  console.log("payee", payee, "\npayee state", state);
  useEffect(() => {
    if (state.type === "remainder") {
      setState({ ...state, amount: payee.amt_remaining });
      return;
    }
    if (!payee.mp_id) {
      setState({ ...state, percentage: "", amount: "" });
    }
  }, [state.type]);
  useEffect(() => {
    let tempArr = [...parentState.payees];
    tempArr[index] = { ...tempArr[index], ...state };
    setParentState({ ...parentState, payees: tempArr });
  }, [state]);

  const handleInput = (e) => {
    if (e.target.name === "percentage") {
      if (parseFloat(e.target.value) > 100) {
        setState({
          ...state,
          percentage: "100",
          amount: parseFloat(payee.amt_bc_owes),
        });
        return;
      }
      setState({
        ...state,
        percentage: e.target.value,
        amount:
          (parseFloat(e.target.value) / 100) * parseFloat(payee.amt_bc_owes),
      });
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h5 className="mt-3">{payee.name} Funding:</h5>
      {singleMode ? (
              <Row>
              <Col xs="6" md="4" lg="4">
                <InputGroup>
                  <InputGroup.Text className="bg-grey">$</InputGroup.Text>
                  <Form.Control
                    min="0"
                    required
                    type="number"
                    name="amount"
                    value={state.amount}
                    onChange={handleInput}
                    placeholder="0.00"
                    step=".01"
                  />
                </InputGroup>
                {validated &&
                  state.type === "fixed" &&
                  (parseFloat(state.amount) <= 0 || state.amount === "") && (
                    <em className="text-danger">
                      Value must be greater than zero dollars.
                    </em>
                  )}
              </Col>
            </Row>
      ) : (
        <Form.Group>
          <Form.Label>
            How much funding will be disbursed at this milestone's completion?
          </Form.Label>
          {/* <p>
            <em>Total funds owed:</em> {dollarFormat(payee.amt_bc_owes)}
          </p> */}
          <p>
            <em>Sum of disbursements already planned:</em>{" "}
            {dollarFormat(payee.disbursement_sum)}
          </p>

          {/* <Form.Check
            className="my-3 "
            type="radio"
            label="Fixed amount."
            onChange={() => setState({ ...state, type: "fixed" })}
            checked={state.type === "fixed"}
            // onClick={() => setstate.type("fixed")}
          /> */}
          {/* <Form.Check
            className="my-3 "
            type="radio"
            checked={state.type === "percent"}
            label="Percentage of total funds owed."
            onChange={() => setState({ ...state, type: "percent" })}
          />
          <Form.Check
            className="my-3 "
            type="radio"
            checked={state.type === "remainder"}
            label={`Remaining amount (${dollarFormat(payee.amt_remaining)}).`}
            onChange={() => setState({ ...state, type: "remainder" })}
          /> */}

          {state.type === "fixed" && (
            <Row>
              <Col xs="6" md="4" lg="4">
                <InputGroup>
                  <InputGroup.Text className="bg-grey">$</InputGroup.Text>
                  <Form.Control
                    min="0"
                    required
                    type="number"
                    name="amount"
                    value={state.amount}
                    onChange={handleInput}
                    placeholder="0.00"
                    step=".01"
                  />
                </InputGroup>
                {validated &&
                  state.type === "fixed" &&
                  (parseFloat(state.amount) <= 0 || state.amount === "") && (
                    <em className="text-danger">
                      Value must be greater than zero dollars.
                    </em>
                  )}
              </Col>
            </Row>
          )}
          {state.type === "percent" && (
            <Row>
              <Col xs="6" md="4" lg="2">
                <InputGroup>
                  <Form.Control
                    min="0"
                    max="100"
                    required
                    type="number"
                    name="percentage"
                    value={state.percentage}
                    onChange={handleInput}
                    placeholder="25"
                    step=".01"
                  />
                  <InputGroup.Text className="bg-grey">%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col className="d-flex align-items-end">
                <b style={{ fontSize: "large" }}>
                  {dollarFormat(state.amount)}
                </b>
              </Col>
            </Row>
          )}
          {state.type === "remainder" && (
            <Row>
              <Col>
                <em>Remaining Amount:</em>{" "}
                <b>{dollarFormat(payee.amt_remaining)}</b>
              </Col>
            </Row>
          )}
        </Form.Group>
      )}
    </>
  );
}

export default PayeeForm;
