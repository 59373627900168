import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useState } from "react";
import MilestoneForm from "../../Milestones/MilestoneForm";
import MultiMilestones from "./MultiMilestones";
import { useHistory } from "react-router";
import axios from "axios";
import Swal from "sweetalert2";
function MilestoneSection({ state, setState, disabled, jobId }) {
  // ? 'single' or 'milestones' or ""
  let history = useHistory();
  const [type, setType] = useState("");
  const afterSubmit = () => {
    axios
      .put("/api/job/" + jobId + "/draft", { isDraft: false })
      .then((res) => {
        history.push("/portal");
        Swal.fire({
          icon: "success",
          title: "Project Created Successfully",
          timer: 3000,
        });
      })
      .catch((err) => {
        console.log("Error submitting project.", err);
        Swal.fire({
          icon: "error",
          title: "Error Submitting Project",
          text: "Please try again.",
          timer: 10000,
        });
      });
  };
  return disabled ? (
    <h4 className="text-secondary">Project Milestones . . . </h4>
  ) : (
    <>
      <h4>Project Milestones</h4>
      <Row>
        <h5>How do you wish to handle project disbursements?</h5>
        <Col xs="4">
          {type === "single" ? (
            <Button variant="primary" onClick={() => setType("")}>
              One single disbursement.
            </Button>
          ) : (
            <Button
              variant="light"
              onClick={() => setType("single")}
              className="border-primary text-primary"
            >
              One single disbursement.
            </Button>
          )}
        </Col>
        <Col xs="3">
          {type === "milestones" ? (
            <Button variant="primary" onClick={() => setType("")}>
              Split into milestones.
            </Button>
          ) : (
            <Button
              variant="light"
              className="border-primary text-primary"
              onClick={() => setType("milestones")}
            >
              Split into milestones.
            </Button>
          )}
        </Col>
      </Row>
      {type === "single" && (
        <MilestoneForm
          jobId={jobId}
          singleMode={true}
          afterSubmit={afterSubmit}
        />
      )}
      {type === "milestones" && (
        <MultiMilestones jobId={jobId} afterSubmit={afterSubmit} />
      )}
    </>
  );
}

export default MilestoneSection;
