import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import MilestonePayee from "./MilestonePayee";
import axios from "axios";

import { dollarFormat } from "../../../../assets/commonFunctions";
function MilestonePayees({ milestoneId }) {
  const [showDetails, setShowDetails] = useState(false);
  const [payees, setPayees] = useState([]);
  const loadData = () => {
    axios
      .get("/api/milestone/" + milestoneId + "/payees")
      .then((res) => setPayees(res.data))
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    loadData();
  }, [milestoneId]);

  return (
    <Container className="bg-light-gre rounded-3 p-3 m-0">
      <h3>Planned Disbursements</h3>

      <h6>
        {/* <u
          className="pointer text-primary"
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "Hide Details" : "Show Details"}
        </u> */}
      </h6>
      <Row className="p-3">
        {payees?.length > 0 &&
          payees.map((payee) => {
            return (
              <Col key={payee.mp_id} xs="12">
                <MilestonePayee payee={payee} showDetails={showDetails} />
              </Col>
            );
          })}
      </Row>
      {payees?.length > 1 && (
        <Row className=" ">
          <Col xs="7">Total funds to be disbursed:</Col>
          <Col className="" style={{ borderTop: "1px solid black" }} xs="5">
            <b>
              {dollarFormat(
                payees.reduce((a, b) => {
                  return (parseFloat(a) || 0) + (parseFloat(b.amount) || 0);
                }, 0)
              )}
            </b>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default MilestonePayees;
