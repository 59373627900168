import React from "react";

function Checkbox({ handleClick, label, checked, disabled }) {
  return !disabled ? (
    <div className="d-flex align-items-center" style={{ fontSize: "large" }}>
      <b className="pointer">
        <i
          style={{ fontSize: "xx-large" }}
          onClick={() => handleClick()}
          className={checked ? " bi-check-square text-success" : " bi-square "}
        ></i>
      </b>
      <span className="ms-2">{label}</span>
    </div>
  ) : (
    <div className="d-flex align-items-center" style={{ fontSize: "large" }}>
    <b className="">
      <i
        style={{ fontSize: "xx-large" }}
        // onClick={() => handleClick()}
        className={checked ? " bi-check-square text-success" : " bi-square "}
      ></i>
    </b>
    <span className="ms-2">{label}</span>
  </div>
  );
}

export default Checkbox;
