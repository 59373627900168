import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";

import Button from "react-bootstrap/Button";

function SearchCustomerPhone(props) {
  const [searchInput, setSearchInput] = useState("");
  const [showErr, setShowErr] = useState(false);
  const searchCustomer = (e) => {
    setShowErr(false);
    e.preventDefault();
    axios
      .get(`/api/customer/search/phone/${searchInput}`)
      .then((res) => {
        props.setCustomerResults(res.data);
        if (res.data.length > 0) {
          props.setCustomerModal(true);
          setSearchInput("");
        } else {
          setShowErr(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onSubmit={searchCustomer}>
      <Form.Group>
        <Form.Label>Search for an existing account for the project owner by their phone number. You must enter their whole number to get results.</Form.Label>
        <InputGroup className="p-0">
          <Form.Control
            type="text"
            name="search"
            disabled={props.disabled}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="You must enter their whole 10 digit phone number. Format does not matter."
          />
          <InputGroup.Text className="p-1">
            <Button type="submit" variant="warning" className="m-0">
              Search
            </Button>
          </InputGroup.Text>
        </InputGroup>
        {showErr ? (
          <Alert dismissible onClose={() => setShowErr(false)} variant="danger">
            No users found with that search. If they are not a Bridge Core
            user yet, please click "New Project Owner".
          </Alert>
        ) : null}
      </Form.Group>
    </Form>
  );
}

export default SearchCustomerPhone;
