import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MilestoneForm from "../../Forms/Milestones/MilestoneForm";

function EditMilestoneModal({ milestone, onHide,show,afterSubmit }) {
  return (
    <Modal size="lg"
    onHide={onHide}
    
    backdrop="static" show={show} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Milestone: {milestone.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MilestoneForm editMode={true} milestone={milestone} jobId={milestone.job_id} afterSubmit={afterSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditMilestoneModal;
