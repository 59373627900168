import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { Link } from "react-router-dom";
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false)
    if(!submitting){
      setSubmitting(true)
      axios
      .post("/api/user/password/forgot", { email })
      .then(() => {
        setEmail("");
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((err) => {
        setSubmitting(false);
        setError(true);
        console.log(err);
      });
    }
  };
  return (
    <Container>
      {success ? (
        <Row className="d-flex justify-content-center">
          <Col xs="12" md="6" className="border rounded-3 border-secondary p-4">
            <h3 className="text-center">Email Sent.</h3>
            <p className="text-center">
              Please check your mailbox for a link to reset your password.
            </p>
            <p  className="text-center">

            <Link to='/' className='pointer hover-blue '><u>Home</u></Link>
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-center">
          <Col xs="12" md="6" className="border rounded-3 border-secondary p-4">
            <h3 className="text-center">Forgot your password ?</h3>
            <p className="text-center">
              Tell us your email and we will send you a link you can use to
              reset it.
            </p>
            {error && (
              <p className="text-danger">
                Sorry, we couldn't find an account with that email address.
              </p>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  disabled={submitting}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@email.com"
                />
              </Form.Group>
              <Button className="my-2 float-end" type="submit" disabled={submitting}>
                {submitting ? "Submitting..." : "Send password reset link"}
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ForgotPassword;
