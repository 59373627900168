import React, {useState, useContext} from 'react'
const CompaniesContext = React.createContext()

export function useCompanies(){
    return useContext(CompaniesContext)
}


function CompaniesProvider({children}) {
    const [currentCompanies, setCurrentCompanies] = useState({})
    let value = {currentCompanies, setCurrentCompanies};

    return (
        <CompaniesContext.Provider value={value}>
            {children}
        </CompaniesContext.Provider>
    )
}

export default CompaniesProvider