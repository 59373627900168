import React from "react";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import MilestoneDeposit from "./MilestoneDeposit";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneDeposits({ milestone }) {
  const [deposits, setDeposits] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadData = () => {
    if (loaded) {
      setLoaded(false);
    }
    axios
      .get("/api/milestone/" + milestone.m_id + "/deposits")
      .then((res) => {
        setDeposits(res.data);
        setLoaded(true);
      })
      .catch((err) => {
        console.log("Error getting deposits: ", err);
        setLoaded(true);

        Swal.fire({
          icon: "error",
          title: "Error getting deposits.",
          text: "Please reload. If the error persists, please contact Bridge Core.",
          timer: 10000,
        });
      });
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <ListGroup className='mb-3'>
      <h3>Milestone Deposits</h3>
      <h4>{dollarFormat(milestone.deposit_sum)}/{dollarFormat(milestone.deposit_total)}</h4>
      {loaded ? (
        deposits?.length > 0 ? (
          deposits.map((deposit) => {
            return <MilestoneDeposit key={deposit.md_id} deposit={deposit} />;
          })
        ) : (
          <em>No deposits have been submitted yet.</em>
        )
      ) : (
        <Container className=" d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
    </ListGroup>
  );
}

export default MilestoneDeposits;
