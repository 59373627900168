import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Intro.scss";

function Intro() {
  
  return (
    <Container
      id="search-container"
      
      className="p-3 mb-3 page-header"
      // style={{ backgroundImage: "url('/images/cranesSm.jpg')" }}
    >
      <Row className="h-100  align-items-center justify-content-center">
        <Col sm="auto" md="6">
          <h2 className="text-light text-center ">ONLY THE BEST PROTECTION FOR YOUR MONEY</h2>
          <p className="text-light text-center">

          Our mission at Bridge Core is to provide a high-quality and secure platform for owners, contractors, and subcontractors to work together efficiently to create safe, secure and fast transactions. 

          </p>
          <p className="text-light text-center">
          Take comfort in uncertain financial times with our proprietary pathway approval system. Payments are securely processed and electronically transferred when work is completed throughout the project. 
          </p>
        </Col>
       
      </Row>
    </Container>
  );
}

export default Intro;
