export const statusColor = (status) => {
  switch (status) {
    case "Declined":
      return "danger";
    case "In Review":
      return "primary";
    case "Submitted":
      return "warning";
    case "Incomplete":
      return "danger";
    case "More information required.":
      return "danger";
    case "Approved":
      return "success";
    case "Canceled":
      return "danger";
    case "Initial Contact":
      return "info";
    case "Requested":
      return "warning";
    case "Quote Sent":
      return "warning";
    case "Complete":
      return "success";
    case "None":
      return "dark";
    default:
      return "primary";
  }
};

export const phoneFormat = (value) => {
  //if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const noNumberScroll = () => {
  return document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
};

export const dollarFormat = (number) => {
  if (!number) {
    return "$0.00";
  }
  let formatted =
    "$" +
    parseFloat(number).toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return formatted;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
