import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import MilestoneEdit from "./MilestoneEdit";
function MilestoneEdits({ milestone }) {
  const [edits, setEdits] = useState([]);
  const [loaded, setLoaded] = useState(false);
  
  //todo axios get edits
  const loadData = () => {
    if (loaded) {
      setLoaded(false);
    }
    let originalId = milestone.m_id 
    if(milestone.original_id && milestone.original_id > 0){
originalId  = milestone.original_id
    }
    axios
      .get("/api/milestone/" + originalId + "/edits")
      .then((res) => {
        setEdits(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    if (milestone.m_id > 0 || milestone.original_id > 0) {
      loadData();
    }
  }, [milestone]);
  return loaded ? (
    <Container
    className='bg-light-gre p-3 rounded-3 m-1'
      style={{ overflowX: "hidden", overflowY: "scroll", height: "400px", width: '99%' }}
    >
      {/* <h5>Milestone Edits</h5> */}
      {edits?.length > 0 &&
        edits.map((edit) => <MilestoneEdit edit={edit} reload={loadData} />)}
    </Container>
  ) : (
    <Container className=" pt-5 d-flex justify-content-center">
      <Spinner variant="primary" animation="grow" />
    </Container>
  );
}

export default MilestoneEdits;
