import React, { Suspense } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import PageHeader from "../PageHeader/PageHeader";
// import ApplicationForm from "./ApplicationForm/ApplicationForm";
import Login from "../Login/Login";
import "./Join.scss";
// import UserSignUp from "../UserSignUp/UserSignUp";

const UserSignUp = React.lazy(() => import("../UserSignUp/UserSignUp"));

function JoinTheFamily() {
  return (
    <Container>
      <PageHeader
        pageTitle="JOIN BRIDGE CORE"
        pageSubtitle="Apply to be a Bridge Core member contractor or sign up to work with one."
      />
      <Suspense
        fallback={
          <Container className="main-content d-flex justify-content-center">
            <Spinner animation="grow" />
          </Container>
        }
      >
        <UserSignUp />
      </Suspense>

      <h2 className="text-center text-primary mt-4 " id="application-header">
        ALREADY A MEMBER?
      </h2>
      <Row>
        <Col xl="8" className="mx-auto">
          <Login toggleModal={() => null} fullSize={true} />
        </Col>
      </Row>
    </Container>
  );
}

export default JoinTheFamily;
