import axios from "axios";
import React from "react";
import { useState } from "react";
import MilestoneForm from "../../Milestones/MilestoneForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
function MultiMilestones({ jobId, newJobMode }) {
  let history = useHistory();
  const [milestones, setMilestones] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showForm, setShowForm] = useState(true);

  //?stats and totals
  const loadMilestones = async () => {
    if (loaded) {
      setLoaded(false);
    }
    axios.get("/api/job/" + jobId + "/milestones").then((res) => {
      setMilestones(res.data?.milestones || []);
      setLoaded(true);
      console.log("resdata", res.data)
      if (res.data?.milestones?.length < 1) {
        setShowForm(true);
      } else {
        setShowForm(false)
      }
    });
  };
  const submitProject = () => {
    axios.put('/api/job/' + jobId +'/draft', {isDraft: false}).then(res => {
      history.push("/portal");
      Swal.fire({
        icon: "success",
        title: "Project Created Successfully",
        timer: 3000,
      });
    }).catch(err => {
      console.log('Error submitting project.',err)
      Swal.fire({icon: 'error', title: "Error Submitting Project",text: "Please try again.", timer: 10000, })
    })
  };
  useEffect(() => {
    loadMilestones();
  }, []);
  const afterSubmit = () => {
    // setShowForm(false)
    loadMilestones();
  };
  return loaded ? (
    <Row>
      {milestones?.length > 0 &&  (
        <Col>
          <h6>Milestones Planned: </h6>
          <Row>
            <Col>
            {milestones.map((milestone,index) => {
              return (
                <Badge className="m-1 ">{index + 1}.{" "}{milestone.title}</Badge>
                );
              })}
              </Col>
          </Row>
        </Col>
      )}
      {!showForm && milestones?.length > 0 ? (
        <>
        <h6>
          Do you want to create another Milestone for this project?{" "}
        </h6>
        <div>

          <Button
            className=""
            onClick={() => {
              setShowForm(true);
            }}
          >
            Yes
          </Button>{" "}
          <Button
            onClick={() => {
              submitProject();
            }}
            variant="success"
            className=""
            >
            No, Submit this Project
          </Button>
            </div>
          </>
      ) : null}

      {showForm && (
        <Col xs="12">
          <MilestoneForm
            // multiMode={true}
            singleMode={false}
            afterSubmit={afterSubmit}
            jobId={jobId}
          />
        </Col>
      )}
    </Row>
  ) : (
    <Container className=" d-flex justify-content-center">
      <Spinner animation="grow" />
    </Container>
  );
}

export default MultiMilestones;
