import React from "react";
import "./Promise.scss";
import Sponsored from "./Sponsored/Sponsored";
import Statement from "./Statement/Statement";
import Container from "react-bootstrap/Container";
import PageHeader from "../PageHeader/PageHeader";

function Promise() {
  return (
    <Container>
      <PageHeader
        pageTitle="OUR PROMISE"
        pageSubtitle="We promise to do everything we can to help your projects go smoothly."
      />
      <Sponsored />
      <Statement />
    </Container>
  );
}

export default Promise;
