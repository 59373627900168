import React,{useEffect} from "react";
import "./Promise.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";

function Promise(props) {
  useEffect(() => {
    const unlisten = props.history.listen(() => {
      window.scrollTo(0,0);
    })
    return () => {
      unlisten();
    }
    // eslint-disable-next-line
  }, [])
  return (
    <Container className="promise-container my-3 py-3">
      <h1 className="text-primary">OUR PROMISE</h1>
      
      <Container id="promises" className="p-3">
        <Row>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
            
              src="/images/blue-logos/licensed.jpg"
              alt=""
            />
            <p className="ms-2 mb-0">Provide a secure payment processing system.</p>
          </Col>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
              
              src="/images/blue-logos/communicate.jpg"
              alt=""  
            />
            <p className="ms-2 mb-0">Provide a platform that makes project owner and contractor collaboration easy.</p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
              src="/images/blue-logos/thumbsUp.jpg"
              alt=""
            />
            <p className="ms-2 mb-0">Provide piece of mind for project owners and contractors.</p>
          </Col>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
              src="/images/blue-logos/star.jpg"
              alt=""
            />
            <p className="ms-2 mb-0">Provide technical support or mediation if required.</p>
          </Col>
        </Row>
        {/* <Row>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
              src="/images/blue-logos/location.jpg"
              alt=""
            />
            <p className="ms-2 mb-0">...have a legitimate physical address</p>
          </Col>
          <Col sm="12" md="6" className="d-flex align-items-center mb-2">
            <img
              className="promise-logos"
              src="/images/blue-logos/star.jpg"
              alt=""
            />
            <p className="ms-2 mb-0">...are referred by their customers</p>
            src="/images/blue-logos/pricing.jpg"
          </Col>
        </Row> */}
      </Container>
      <Button onClick={() => props.history.push('/promise')}>LEARN MORE</Button>
    </Container>
  );
}

export default withRouter(Promise);
