import React, { useState, useEffect } from "react";
import "./CompanyProfile.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
// import MessageCompany from "./MessageCompany/MessageCompany";

import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { phoneFormat } from "../../../../assets/commonFunctions";

function CompanyProfile(props) {
  const [services, setServices] = useState();
  const [companyInfo, setCompanyInfo] = useState();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    axios
      .get(`/api/company/${props.match.params.id}`)
      .then((res) => {
        setCompanyInfo(res.data);
        setLoaded(true);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/company/services/${props.match.params.id}`)
      .then((res) => {
        setServices(res.data);
        // setLoaded(true)
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      {loaded ? (
        <>
          <Row className="d-flex justify-content-center">
          <h2
                id="company-profile-header"
                className="text-center text-primary my-3"
              >
                {companyInfo.name.toUpperCase()}
              </h2>
            <Col xs="12" lg="6">
              {companyInfo.company_img && (
                <Row>
                  <Col className="d-flex justify-content-center my-2">
                    <img
                      id="company-image"
                      src={companyInfo.company_img}
                      alt={companyInfo.name + " company logo"}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <h5 className="text-center">
                    {companyInfo.street} {companyInfo.city}, {companyInfo.state}{" "}
                    {companyInfo.zip_code}
                  </h5>
                </Col>
              </Row>
              <Row className="text-center ">
                <Col xs="12">
                  <a href={`tel: ${companyInfo.phone.replaceAll(/\D/g, "")}`}>
                    <span>{phoneFormat(companyInfo.phone)}</span>
                  </a>
                </Col>
                <Col xs="12">
                  <a href={`mailto: ${companyInfo.contact_email}`}>
                    <span>{companyInfo.contact_email}</span>
                  </a>
                </Col>
                <Col xs="12">
                  <a
                    href={`https://${companyInfo.website_link}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <span>{companyInfo.website_link}</span>
                  </a>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="text-center">
                  <span>License #: {companyInfo.license}</span>
                </Col>
                <Col xs="12" className="">
                  <Row>
                    <Col xs="12" sm="6" id="insurance" className="mx-0 ">
                      Insurance Provider:{" "}
                    </Col>
                    <Col
                      xs="12"
                      sm="6"
                      id="insurance-value"
                      className="mx-0 p-0"
                    >
                      {companyInfo.insurance}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs="12" lg="6">
              
              <Container id="bio">{companyInfo.bio}</Container>
              <section className="border rounded-3 p-2">
                <h4 className="text-center text-primary mt-3 ">
                  Services Offered
                </h4>
                <Container className="d-flex flex-wrap align-items-center mb-5">
                  {services &&
                    services.map((s) => {
                      return (
                        <Badge
                          key={s}
                          bg="light"
                          pill
                          className="p-3 bg-light text-primary border border-secondary m-1"
                        >
                          {s}
                        </Badge>
                      );
                    })}
                </Container>
              </section>
            </Col>
          </Row>
          {/* <MessageCompany companyName={companyInfo.name} /> */}
        </>
      ) : (
        <Row>
          <Col className="text-center">
            <Spinner
              className="company-spinner"
              animation="grow"
              variant="primary"
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default withRouter(CompanyProfile);
