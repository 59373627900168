import React from "react";
import Container from "react-bootstrap/Container";
import "./EntityCard.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function EntityCard(props) {
  return (
    <Container className="rounded-3 d-flex align-items-center entity shadow-sm p-3 mb-3">
      <Row>
        <Col
          xs="12"
          md="3"
          className=" p-3 d-flex justify-content-center align-items-center"
        >
          <img className="entity-img" src={props.imgUrl} alt="" />
        </Col>
        <Col xs="12" md="9" className="d-flex align-items-center">
          <Container>
            <h4>{props.title}</h4>
            <p className="mb-2">{props.desc}</p>
            {props.desc2 && <p  className="mb-2">{props.desc2}</p>}
            {props.desc3 && <p>{props.desc3}</p>}
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default EntityCard;
