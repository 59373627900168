import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { withRouter } from "react-router-dom";
import axios from "axios";

import { useUser } from "../../../UserContext";

const ResetPasswordForm = React.lazy(() =>
  import("../../Company/CompanyPortal/Forms/ResetPasswordForm")
);
// import {
//   getAuth,
//   signInWithCustomToken,
//   setPersistence,
//   browserLocalPersistence,
// } from "firebase/auth";
function PasswordReset(props) {
  console.log(props);
  let userContext = useUser();
  // let auth = getAuth()
  const [user, setUser] = useState({ auth: false });
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    console.log(props.match.params.code);
    let code = props.match.params.code;
    if (code) {
      axios.get(`/api/auth/customer/${code}`).then((res) => {
        console.log(res.data);
        if (res.data.user_id) {
          setUser({ auth: true, ...res.data });
        }
        setLoaded(true);
      });
    }
  }, [props.match.params.code]);
  const goToPortal = (password) => {
    let email = user.email;
    axios
      .post("/api/auth/login", {
        email: email.replaceAll(" ", "").toLowerCase(),
        password,
      })
      .then(async (res) => {
        // await setPersistence(auth, browserLocalPersistence).then(() => {
        //   signInWithCustomToken(auth, res.data.token)
        //     .then((userCred) => {
        //       console.log("Logged in to Firebase.");
        //     })
        //     .catch((err) => console.log("firebase error", err));
        // });

        await userContext.setCurrentUser(res.data);
        if (res.data.role === "admin") {
          props.history.push("/admin");
        }
        if (res.data.company) {
          props.history.push("/portal");
        }

        // ! not tested
        if (res.data.user_id && !res.data.company && !res.data.role) {
          props.history.push("/portal");
        }
        if (props.setLoggedIn) {
          props.setLoggedIn(true);
        }
        if (props.setExpanded) {
          props.setExpanded(false);
        }
        axios
          .delete(`/api/customer/code/${res.data.user_id}`)
          .then((res) => console.log("Code removed."))
          .catch((err) => console.log("err: Code not removed.", err));
      })
      .catch((err) => {
        console.log("err", err.response, err);
      });
  };
  return (
    <Container>
      <Row>
        <Col>
          {loaded ? (
            user.auth ? (
              <>
                <h4>
                  Reset Password for {user.first_name + " " + user.last_name}
                </h4>
                <ResetPasswordForm
                  code_hash={props.match.params.code}
                  company={user}
                  goToPortal={goToPortal}
                />
              </>
            ) : (
              <p>Link invalid.</p>
            )
          ) : (
            <p>Checking your link...</p>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(PasswordReset);
