import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import SearchCompanyForm from "../../../../Company/SearchCompanyForm";
import Swal from "sweetalert2";
function Subcontractors({
  jobId,
  primary,
  setPrimary,
  services,
  setCurrentSlide,
  subsDone,
  // subs,
  // setSubs,
  editSub,
  setEditSub,
  removeSub,
  disabled,
  createJob,
}) {
  const [state, setState] = useState({
    name: "",
    company_id: "",
    service: "",
    amt_bc_owes: "0",
    disabled: true,
    nextDisabled: true,
  });
  const [companyResults, setCompanyResults] = useState([]);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [creatingJob, setCreatingJob] = useState(false);
  // console.log("Subcontractor states: ", {
  //   state,
  //   showForm,
  //   submitting,
  //   editSub,
  // });
  const [subs, setSubs] = useState([]);
  useEffect(() => {
    validate();
  }, [state.company_id, state.service, state.amount_bc_owes]);
  useEffect(() => {
    if (editSub?.show && editSub?.sub.name) {
      setState({
        ...state,
        ...editSub?.sub,
        prevOwes: editSub?.sub.amt_bc_owes,
      });
      setShowForm(true);
    }
    if (!editSub?.show && state.company_id > 0) {
      clearForm();
      setShowForm(false);
    }
  }, [editSub]);

  const validate = () => {
    if (
      state.service &&
      // state.amt_bc_owes !== "" &&
      state.company_id
    ) {
      setState({ ...state, disabled: false });
    } else {
      setState({ ...state, disabled: true });
    }
  };
  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault(e);
    if (editSub?.show && editSub?.sub.jc_id) {
      axios
        .put("/api/job/sub", { ...state, jobId })
        .then((res) => {
          axios
            .put("/api/admin/job/primary/owes", {
              amt_bc_owes:
                parseFloat(primary.amt_bc_owes) -
                (parseFloat(state.amt_bc_owes) - parseFloat(state.prevOwes)),
              jc_id: primary.jc_id,
            })
            .then((res) => {
              console.log("sub edit primary resdata", res.data);
              setPrimary({ ...primary, ...res.data[0] });
              setShowForm(false);
              setSubmitting(false);
              clearForm();
              Swal.fire({
                icon: "success",
                title: "Subcontractor updated successfully!",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          let subArr = subs?.filter(
            (s) => s.company_id !== res.data.company_id
          );
          console.log("edit sub res.data", res.data);
          setSubs([...subArr, res.data]);
          setEditSub({ show: false, sub: {} });
          // getJobTotal()
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post("/api/job/sub", { ...state, job_id: jobId })
        .then((res) => {
          // axios
          //   .put("/api/job/primary/owes", {
          //     amt_bc_owes:
          //       parseFloat(primary.amt_bc_owes) - parseFloat(state.amt_bc_owes),
          //     jc_id: primary.jc_id,
          //   })
          //   .then((res) => {
          // console.log("new sub primary resdata", res.data);

          // setPrimary({ ...primary, ...res.data[0] });
          setShowForm(false);
          setSubmitting(false);
          clearForm();
          Swal.fire({
            icon: "success",
            title: "Subcontractor added successfully!",
            timer: 1500,
            showConfirmButton: false,
            // });
          });
          setSubs([...subs, res.data]);
        })
        .catch((err) => console.log(err));
    }
  };
  const clearForm = () => {
    setState({
      company_id: 0,
      service: "",
      amt_bc_owes: "",
      amt_bc_paid: "",
    });
  };
  console.log("subs", subs);
  return disabled ? (
    <h4 className="text-secondary">Subcontractors . . .</h4>
  ) : (
    <>
      <h4 className="">Subcontractors</h4>{" "}
      {subs?.length > 0 && (
        <Container className="my-3 ps-1">
          {subs.map((sub) => {
            return <Badge className="me-3">{sub.name}</Badge>;
          })}
        </Container>
      )}
      {!showForm && !subsDone && !creatingJob ? (
        <h6>
          Do you want to add a{subs?.length > 0 ? "nother" : ""} subcontractor
          to this job?{" "}
          <Badge
            className="btn"
            onClick={() => {
              createJob("yes");
              setShowForm(true);
            }}
          >
            Yes
          </Badge>{" "}
          <Badge
            onClick={() => {
              createJob("no");
              setCreatingJob(true);
            }}
            bg="danger"
            className="btn"
          >
            No
          </Badge>
        </h6>
      ) : null}
      {showForm ? (
        jobId > 0 ? (
          <>
            <h3>
              {editSub?.show
                ? `Editing ${editSub?.sub.name}`
                : "Add Subcontractor"}
            </h3>
            {!editSub?.show ? (
              <>
                <SearchCompanyForm
                  setCompanyModal={setShowCompanies}
                  setCompanyResults={setCompanyResults}
                  subsOnly={true}
                />
                <ListGroup>
                  {showCompanies ? (
                    <>
                      <h5 className="text-center text-primary">
                        Select a contractor from the list below:
                      </h5>

                      {companyResults &&
                        companyResults
                          .filter((c) => {
                            return (
                              subs?.filter((s) => s.company_id === c.company_id)
                                .length < 1
                            );
                          })
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((c) => {
                            return (
                              <ListGroup.Item
                                key={c.company_id}
                                className="btn shadow"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    company_id: c.company_id,
                                    name: c.name,
                                  });

                                  setShowCompanies(false);
                                  Swal.fire({
                                    title: "Company added.",
                                    timer: 800,
                                    showConfirmButton: false,
                                  });
                                }}
                              >
                                <b>{c.name + " - ID:" + c.company_id}</b>
                              </ListGroup.Item>
                            );
                          })}
                    </>
                  ) : null}
                </ListGroup>
              </>
            ) : null}

            <Form onSubmit={handleSubmit}>
              <Row>
                {state.name ? (
                  <Form.Group
                    as={Col}
                    xs="12"
                    id="contractor-field"
                    className="align-self-end"
                  >
                    {editSub?.show ? (
                      <p>
                        <em className="text-secondary">
                          You cannot change the subcontractor selected when
                          editing. If you wish to do so, remove this one and
                          start again.
                        </em>
                      </p>
                    ) : (
                      <Form.Label className=" mt-2">
                        Subcontractor Name
                      </Form.Label>
                    )}

                    <Form.Control
                      type="text"
                      name="name"
                      value={state.name}
                      disabled
                      placeholder="Search for a contractor above and select it."
                    />
                  </Form.Group>
                ) : null}
                {/* <Form.Label className="mt-3 ">
                Amount owed to Subcontractor
              </Form.Label>
              <Form.Label className="m-0 ">
                <em className="text-secondary">
                  This represents to total amount Bridge Core will pay this
                  company.
                </em>
              </Form.Label>
              <Form.Group as={Col} xs="5" className="align-self-end">
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    required
                    value={state.amt_bc_owes}
                    min="0"
                    type="number"
                    placeholder="0.00"
                    onChange={(e) =>
                      setState({ ...state, amt_bc_owes: e.target.value })
                    }
                  />
                  <FormControl.Feedback type="invalid">
                    Enter a value of zero or more.
                  </FormControl.Feedback>
                  <FormControl.Feedback type="valid">
                    Looks good!
                  </FormControl.Feedback>
                </InputGroup> */}
                {/* </Form.Group> */}
                <Form.Group
                  as={Col}
                  xs="12"
                  sm="6"
                  className="mt-1 align-self-end"
                >
                  <Form.Label className="m-0">Service Provided</Form.Label>
                  <Form.Select
                    value={state.service}
                    onChange={(e) => {
                      setState({ ...state, service: e.target.value });
                    }}
                    name="service"
                    required
                  >
                    <option value="">--</option>

                    {services &&
                      services[0] &&
                      services
                        ?.sort((a, b) =>
                          a.service_name.localeCompare(b.service_name)
                        )
                        .map((s) => {
                          return (
                            <option key={s.service_name} value={s.service_name}>
                              {s.service_name}
                            </option>
                          );
                        })}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col>
                  {editSub?.show ? (
                    <>
                      <Button
                        className="float-start my-3"
                        variant="secondary"
                        onClick={() => {
                          removeSub(editSub?.sub.jc_id);
                        }}
                      >
                        Remove from this project.
                      </Button>
                      <Button
                        className="float-start m-3"
                        variant="danger"
                        onClick={() => {
                          clearForm();
                          setEditSub({ show: false, sub: {} });
                          setShowForm(false);
                        }}
                      >
                        Cancel Edit
                      </Button>
                    </>
                  ) : null}

                  <Button
                    type="submit"
                    disabled={state.disabled || state.submitting}
                    className="float-end my-3"
                  >
                    {submitting
                      ? "Adding..."
                      : editSub?.show
                      ? "Submit Changes"
                      : "Add Subcontractor"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : !jobId || jobId < 1 ? (
          <Container className=" d-flex justify-content-center">
            <Spinner animation="grow" />
          </Container>
        ) : null
      ) : null}
      {/* <Button
        className="float-end my-3"
        onClick={() => setCurrentSlide(4)}
        variant="success"
        disabled={state.showForm}
      >
        Next
      </Button> */}
    </>
  );
}

export default Subcontractors;
