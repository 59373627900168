//COMPCARD IS SHORT FOR COMPANY CARD
import React from "react";
import "./CompCard.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { withRouter } from "react-router";
// import Tooltip from "react-bootstrap/Tooltip";

function CompCard(props) {
  let company = props.comp;
  // let renderTooltip = () => {
  //   return (<Tooltip>See all they offer!</Tooltip>);
  // };
  return (
    <Container className="shadow mb-2 bg-light rounded-3">
      <Row className="p-2 ">
        <Col
          xs="12"
          md="2"
          className="d-flex justify-content-center align-items-center"
        >
          <img
            className="rounded-circle company-logos "
            src={`https://ui-avatars.com/api/?name=${company.name}`}
            alt="Company logo"
          />
        </Col>
        <Col
          xs="12"
          md="7"
          className="d-flex flex-column justify-content-center align-items-center align-items-md-start p-2 "
        >
          <h3 className="comp-name">{company.name}</h3>
          <p className="m-0">
            {company.city}, {company.state}
          </p>
        </Col>
        <Col
          xs="12"
          md="3"
          className="d-flex justify-content-center align-items-center"
        >
          {/* <OverlayTrigger
            placement="bottom"
            // delay={{show: 100, hide: 400}}
            overlay={<Tooltip>View all their services.</Tooltip>}
          > */}
            <Button
              className="p-3"
              onClick={() =>
                props.history.push(`/company/${company.company_id}`)
              }
            >
              VIEW THEIR PROFILE
            </Button>
          {/* </OverlayTrigger> */}
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(CompCard);
