import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { capitalizeFirstLetter, dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneDisbursement({ disbursement }) {
  return (
    <ListGroup.Item >
      <Row>
        <Col xs="12">
            <h5>{disbursement.name}</h5>
          <h5 className='text-success'>{dollarFormat(disbursement.amount)}</h5>
        </Col>
        <Col>
          <span className="">
            Recorded: {new Date(disbursement.created).toLocaleDateString()}
          </span>
        </Col>
        {/* <Col>
        <span>
            Status: <b>{capitalizeFirstLetter(disbursement.payment_status)}{disbursement.disbursement_complete && ' ✅'}</b> 
        </span>
        </Col> */}
      </Row>
    </ListGroup.Item>
  );
}

export default MilestoneDisbursement;
