import React from "react";
import Intro from "./Intro/Intro";
import Promise from "./Promise/Promise";
import Join from "./Join/Join";
import Escrow from "./Escrow/Escrow";
import SearchFilter from "../SearchFilter/SearchFilter";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Landing() {
  return (
    <>
      <Intro />
      <Promise />
      <Escrow />
      <Join />
      <Row className="d-flex justify-content-center p-03">
        <Col xs="12" md="6" className="">
          <SearchFilter />
        </Col>
      </Row>
    </>
  );
}

export default Landing;
