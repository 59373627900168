import React from "react";
import "./Milestone.scss";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import MilestonePayees from "./MilestonePayees";
import MilestoneEdits from "./MilestoneEdits";
import { useState } from "react";
import EditMilestoneModal from "./EditMilestoneModal";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { dollarFormat } from "../../../../assets/commonFunctions";
import MilestoneActions from "./MilestoneActions";
import MilestoneDeposits from "./MilestoneDeposits";
import { useUser } from "../../../../UserContext";
import MilestoneDisbursements from "./MilestoneDisbursements";
function Milestone({ milestone, reload }) {
  const [editModal, setEditModal] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdits, setShowEdits] = useState(false);
  let user = useUser("user");
  let afterEditSubmit = () => {
    setEditModal(false);
    reload();
  };
  const approve = () => {
    Swal.fire({
      icon: "warning",
      title: "Approve this milestone?",
      text: "Be sure to review all of the details of this milestone before proceeding. This will reject any other versions of this milestone you have approved in the past.",
      showCancelButton: true,
      confirmButtonText: "Approve",
    }).then((answer) => {
      if (answer.isConfirmed) {
        axios
          .post("/api/milestone/approval", {
            milestone_id: milestone.m_id,
            approve: true,
          })
          .then((res) => {
            reload();
            Swal.fire({
              icon: "success",
              title: "You approved this milestone.",
              timer: 2000,
            });
          });
      }
    });
  };
  const MilestoneLabel = () => {
    let label = { text: "", color: "" };
    if (milestone.is_current) {
      label = { text: "APPROVED MILESTONE", color: "success" };
    } else if (milestone.is_original) {
      if (milestone.edit_count > 0) {
        if (milestone.has_current_edit) {
          label = {
            text: "ORIGINAL MILESTONE (Replaced by Approved Edit)",
            color: "danger",
          };
        } else {
          label = {
            text: "ORIGINAL MILESTONE (Edit Submitted)",
            color: "danger",
          };
        }
      } else if (milestone.rejected_count > 0) {
        label = { text: "ORIGINAL MILESTONE (Rejected)", color: "danger" };
      } else {
        label = { text: "ORIGINAL MILESTONE", color: "primary" };
      }
    } else {
      if (milestone.rejected_count > 0) {
        label = { text: "PROPOSED EDIT (Rejected)", color: "danger" };
      } else {
        label = { text: "PROPOSED EDIT ", color: "purple" };
      }
    }
    if (milestone.complete_count > 1) {
      label = { text: "Marked Complete by Both Parties ✅", color: "success" };
    }
    return (
      <h6 className="edit-text-parent">
        <span className={`original-text text-${label.color} rounded-3 p-1`}>
          {label.text}
        </span>
      </h6>
    );
  };

  const CustomApprovalStatus = () => {
    let approvalStatus = "";

    if (milestone.rejected_count > 0) {
      if (milestone.rejected_count > 1) {
        approvalStatus =
          "Both parties rejected this milestone. Check the Edit History for any proposed changes.";
      } else {
        if (milestone.user_approval_status === "rejected") {
          if (milestone.user_created_edits) {
            approvalStatus = "You submitted an edit.";
          } else {
            approvalStatus =
              "You rejected this milestone. Consider submitting an edit.";
          }
        } else if (milestone.user_is_customer) {
          approvalStatus =
            milestone.primary_company_name +
            " rejected this milestone. Check the Edit History for any proposed changes.";
        } else {
          approvalStatus =
            milestone.customer_name +
            " rejected this milestone. Check the Edit History for any proposed changes.";
        }
      }
    } else if (milestone.not_approved_count > 0) {
      if (milestone.user_approval_status === "pending") {
        approvalStatus = "Pending your approval.";
      } else if (milestone.user_is_customer) {
        approvalStatus =
          "Pending " + milestone.primary_company_name + "'s approval.";
      } else {
        approvalStatus =
          "You approved this milestone. Pending " +
          milestone.customer_name +
          "'s approval.";
      }
    }

    if (milestone.is_current) {
      approvalStatus = "Approved by both parties.";
    }
    return (
      <em>
        <b>{approvalStatus}</b>
      </em>
    );
  };


  console.log("Milestone", milestone.m_id, milestone);
  return (
    <>
      {" "}
      <Container
        className={`${
          show ? "milestone-head-active" : "milestone-head"
        } py-3 m-0 `}
        onClick={() => setShow(!show)}
      >
        <MilestoneLabel />
        <Row>
          <Col>
            <h3>
              {milestone.title ? milestone.title : "Whole Project Milestone"}
              {milestone.locked ? (
                <i className="bi bi-lock float-end"></i>
              ) : (
                <i className="text-secondary bi bi-unlock-fill float-end"></i>
              )}
            </h3>{" "}
          </Col>
          <Col xs="1">
            <i
              className={`float-end bi bi-chevrPon-${show ? "up" : "down"}`}
            ></i>
          </Col>
          <Col xs="12">
            <span className="text-secondary">
              <em>Created by </em>{" "}
              {milestone.author_id === user?.currentUser?.user_id
                ? " you."
                : milestone.author_name}
            </span>
          </Col>
          <Col xs="12">
            {" "}
            <Row className="mb-4 text-primary">
              <Col>
                <CustomApprovalStatus />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {show && (
        <>
          <Container
            style={{ minHeight: "300px" }}
            className={
              (milestone.is_original
                ? "milestone milestone-original"
                : " milestone milestone-edit ") + " mb-1 py-3"
            }
          >
            <MilestoneActions reload={reload} milestone={milestone} />

            <Row>
              {/* <Col xs="12">
                <span className="d-block">
                  <h1 className="text-center">{milestone.title} </h1>
                </span>
              </Col> */}
              <Col xs="12">
                <span>
                  <em>Definition:</em>
                </span>
                <blockquote className="definition-blockquote">
                  <span>{milestone.definition}</span>
                </blockquote>
              </Col>
            </Row>
            <MilestoneDeposits milestone={milestone} />
            <Row className="my-4">
              <Col xs="12" md="6" className="p-1">
                <MilestonePayees milestoneId={milestone.m_id} />
              </Col>
            </Row>
            <Row className="my-4">
              <Col xs="12" className="p-1"></Col>
            </Row>
            <MilestoneDisbursements milestone={milestone} />
            <Row>
              {milestone.m_id &&
                (milestone.is_current || !milestone.edit_is_current) && (
                  <>
                    <Col
                      xs="7"
                      onClick={() => {
                        if (
                          milestone.edit_count > 0 &&
                          ((milestone.is_original &&
                            !milestone.has_current_edit) ||
                            milestone.is_current)
                        ) {
                          setShowEdits(!showEdits);
                        }
                      }}
                    >
                      <h4 className="d-inline pointer">
                        Edit History (
                        <u className="pointer text-primary">
                          {milestone.edit_count}
                        </u>
                        )
                      </h4>
                      {milestone.edit_count > 0 &&
                        ((milestone.is_original &&
                          !milestone.has_current_edit) ||
                          milestone.is_current) && (
                          <u className="ms-2 pointer">
                            {showEdits ? "Hide " : "Show "} Edits
                          </u>
                        )}
                    </Col>
                    {!milestone.locked &&
                      (milestone.is_current ||
                        (milestone.is_original &&
                          !milestone.has_current_edit)) && (
                        <Col xs="5">
                          <div
                            className="mb-2 pointer "
                            onClick={() => setEditModal(true)}
                          >
                            <i className="bi bi-pencil-square"></i>
                            <u> Make Edit</u>
                          </div>
                        </Col>
                      )}
                    {showEdits && milestone.edit_count > 0 && (
                      <>
                        <MilestoneEdits milestone={milestone} />
                      </>
                    )}{" "}
                  </>
                )}
            </Row>
            <EditMilestoneModal
              milestone={milestone}
              show={editModal}
              afterSubmit={() => afterEditSubmit()}
              onHide={() => setEditModal(false)}
            />
          </Container>
        </>
      )}
    </>
  );
}

export default Milestone;
