import React, { useState, useEffect } from "react";
import "./SearchFilter.scss";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { states } from "../../../assets/states";
import { withRouter } from "react-router";
import axios from "axios";


function SearchFilter(props) {
  const [state, setState] = useState("");
  const [service, setService] = useState("");
  const [services, setServices] = useState([]);
  useEffect(() => {
    axios
      .get("/api/services")
      .then((res) => setServices(res.data))
      .catch((err) => console.log(err));

    if(props.service){
      setService(props.service)
    }
    if(props.state){
      setState(props.state)
    }

    // eslint-disable-next-line
  }, []);
 
  //routes to the companies page with approprate values in the url
  const search = () => {
    //github issues has details on an issue with the search filter.
    if (state && service) {
      props.history.push(`/companies?state=${state}&service=${service}#list`);
    } else if (state !== "default") {
      props.history.push(`/companies?state=${state}#list`);
    } else if (service !== "default") {
      props.history.push(`/companies?service=${service}#list`);
    } else {
      props.history.push("/companies/#list");
    }
  };

  return (
    <Container className="rounded p-3 h-100 d-flex flex-column justify-content-center search-form">
      <h2 className="text-secondary text-center ">
        <em>Find a</em>{" "}
        <span className="text-primary bold">MEMBER CONTRACTOR</span>
      </h2>
      <Form className="">
        <Row className="g-2 justify-content-center ">
          <Col sm="12" lg="6">
            <FloatingLabel controlId="selectState" label="Location">
              <Form.Select
                value={state}
                onChange={(e) => setState(e.target.value)}
                aria-label="Floating label select example"
              >
                <option value="">Select a location</option>
                {states &&
                  states.map((s) => {
                    return (
                      <option value={s} key={s}>
                        {s}
                      </option>
                    );
                  })}
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col sm="12" lg="6">
            <FloatingLabel label="Service">
              {/* Dynamically generate via Axios */}
              <Form.Select
                onChange={(e) => setService(e.target.value)}
                value={service}
                aria-label="Floating label select example"
              >
                <option value="">Select a service</option>
                {services &&
                  services
                    .sort((a, b) =>
                      a.service_name.localeCompare(b.service_name)
                    )
                    .map((s) => {
                      return (
                        <option value={s.service_name} key={s.service_name}>
                          {s.service_name}
                        </option>
                      );
                    })}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="p-3">
          <Button onClick={() => search()}>Search</Button>
        </Row>
      </Form>
    </Container>
  );
}
export default withRouter(SearchFilter);
