import React from "react";
import Container from "react-bootstrap/Container";
import "./Statement.scss";

function Statement() {
  return (
    <Container className="p-5 d-flex flex-column align-items-center">
      <h1 id="statement-header" className="text-primary text-center">
        WE PROMISE TO HAVE YOUR BACK
      </h1>
      <p className='text-center'>
        We only support companies that have a proven history of quality
        work and excellent customer service. We serve as an intermediary between
        project owner and contractor when disagreements happen. We keep both parties
        informed regarding the status of the project at all times. Whether you’re a
        project owner or contractor, regardless of what happens, we will be on your
        side. You can trust them because you can trust us.
      </p>
    </Container>
  );
}

export default Statement;
