import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./Escrow.scss";
import { withRouter } from "react-router";

function Escrow(props) {
  return (
    <Container className="p-4">
      <Row className="d-flex justify-content-around mb-2">
        <Col xs="12">
          <h1 className="text-center text-primary">OUR ESCROW SERVICES</h1>
          <h6 className="text-center">
            The Bridge Core system is designed and built to support project owners
            and contractors.{" "}
          </h6>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between mb-4">
        <Col xs="12" lg="3">
          <Card className=" p-3 mb-3 d-flex flex-column align-items-center text-center h-100">
            <Card.Title>PROJECT OWNER</Card.Title>
            <img
              className="escrow-card-img"
              src="/images/blue-logos/customer.jpg"
              alt=""
            />
            <Card.Text>
              The project owner and contractor define project completion milestones
              in the Bridge Core system. The project owner gives Bridge Core the
              funds so the project can get started.{" "}
            </Card.Text>
          </Card>
        </Col>
        <Col
          lg="1"
          className="d-flex justify-content-center align-items-center"
        >
          <i className=" text-secondary bi bi-caret-right-fill escrow-arrows"></i>
        </Col>
        <Col lg="3">
          <Card className=" p-3 mb-3 d-flex flex-column align-items-center text-center h-100">
            <Card.Title>BRIDGE CORE</Card.Title>
            <img
              className="escrow-card-img"
              src="/images/BC-logo-fav.png"
              alt=""
            />
            <Card.Text>
              After the funds are deposited in Bridge Core’s
              secure escrow account, they will be held for the duration of the
              project.
            </Card.Text>
          </Card>
        </Col>
        <Col
          lg="1"
          className="d-flex justify-content-center align-items-center"
        >
          <i className=" text-secondary bi bi-caret-right-fill escrow-arrows"></i>
        </Col>
        <Col xs="12" lg="3">
          <Card className="p-3 mb-3 d-flex flex-column align-items-center text-center h-100">
            <Card.Title>CONTRACTOR</Card.Title>
            <img
              className="escrow-card-img"
              src="/images/blue-logos/company.jpg"
              alt=""
            />
            <Card.Text>
              Bridge Core pays the contractor as defined project milestones are
              completed through the pathway approval system.
            </Card.Text>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="d-flex justify-content-center">
          <Button onClick={() => props.history.push("/escrow")}>
            LEARN MORE
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(Escrow);
