import React from "react";
import Container from "react-bootstrap/Container";
import "./PageHeader.scss";
function PageHeader(props) {
  return (
    <Container
      id="search-container "
      className="p-3 mb-3 d-flex flex-column page-header justify-content-center"
      // style={{ backgroundImage: "url('/images/cranesSm.jpg')" }}
    >
      <h1 className="text-light text-center font-weight-bolder">
        {props.pageTitle}
      </h1>
      <p className="text-light text-center font-weight-bolder">
        {props.pageSubtitle}
      </p>
    </Container>
  );
}

export default PageHeader;
