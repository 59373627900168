import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
function SearchCompanyForm({ setCompanyResults, setCompanyModal, subsOnly }) {
  const [searchInput, setSearchInput] = useState("");

  const searchCompanies = (e) => {
    e.preventDefault();
    axios
      .get(
        `/api/companies/search/${searchInput}?exclude_current_user=${
          subsOnly ? "true" : "false"
        }`
      )
      .then((res) => {
        setCompanyResults(res.data);
        if (res.data.length > 0) {
          if (setCompanyModal) {
            setCompanyModal(true);
          }
          setSearchInput("");
        } else {
          Swal.fire({
            icon: "warning",
            title: "No companies found.",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Form onSubmit={searchCompanies}>
      <Form.Group>
        <Form.Label>Search by Company Name</Form.Label>
        <InputGroup className="p-0">
          <Form.Control
            type="text"
            required
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="All or part of contractor's company name."
          />
          <InputGroup.Text className="p-1">
            <Button variant="warning" type="submit" className="m-0">
              Search
            </Button>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </Form>
  );
}

export default SearchCompanyForm;
