import React from "react";
import NewJobForm from "./NewJobForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useUser } from "../../../../../UserContext";
function NewJobPage() {
  let user = useUser("company");
  // const [authorized, setAuthorized] = useState(false)
  return (
    <Container>
      {user?.currentUser?.permissions?.user ? (
        <NewJobForm />
      ) : (
        <Container className="main-content d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
    </Container>
  );
}

export default NewJobPage;
