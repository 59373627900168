import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { dollarFormat } from "../../../../assets/commonFunctions";
import PayeeProgress from "./PayeeProgress";
function MilestonePayee({ payee, showDetails }) {
  return showDetails ? (
    <Row>
      <Col xs="12">
        <b>{payee.name}</b>
      </Col>
      {/* <Row>
        <Col xs="7">Total amount owed for this project:</Col>
        <Col xs="5">
          <b>{dollarFormat(payee.amt_bc_owes)}</b>
        </Col>
      </Row>
      <Row className="text-success">
        <Col xs="7">Total amount already paid for this project:</Col>
        <Col xs="5">
          <b>{dollarFormat(payee.amt_bc_paid)}</b>
        </Col>
      </Row> */}
      <Row className="text-primary">
        <Col xs="7">This disbursement amount:</Col>
        <Col xs="5">
          {" "}
          <b>{dollarFormat(payee.amount)}</b>
          <br />
          {payee.is_percent ? (
            <span>{payee.percent_decimal * 100}% of total owed</span>
          ) : payee.is_remainder ? (
            " (Remaining Amount)"
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="11">
          {/* <PayeeProgress payee={payee} /> */}
        </Col>
      </Row>
    </Row>
  ) : (
    <Row>
      <Col xs="7">
        <b>{payee.name}</b>
      </Col>
      <Col xs="5">
        {" "}
        <b>{dollarFormat(payee.amount)}</b>
        {payee.is_percent
          ? ` (${payee.percent_decimal * 100}%)`
          : payee.is_remainder
          ? " (Remaining Amount)"
          : ""}
      </Col>
    </Row>
  );
}

export default MilestonePayee;
