import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
const UserContext = React.createContext();
export function useUser(authType) {
  let history = useHistory();
  authType = authType || "infoOnly";
  let userContext = useContext(UserContext);
  let currentUser = userContext?.currentUser || {};

  const kick = () => {
    console.log("Not authorized, signing out.");
    if(currentUser.role === 'admin'){
      history.push('/admin')
      return
    }
    userContext.setCurrentUser({ user_id: 0, loaded: false, authorized: false });
    axios.post("/api/auth/logout");
    history.push("/");
  };
  const authorizeUser = async () => {
    // };
    let authorized = false;
    let tempUser = currentUser;
    // console.log("tempUser", tempUser);
    if (!tempUser?.user_id) {
      let sessionUser = await axios.get("/api/user").catch((err) => {
        console.log(err);
      });
      sessionUser = sessionUser.data;
      // console.log("sessionUser", sessionUser);

      tempUser = sessionUser || {};
    }

    // tempUser.authorized = false;
    let permissions = {
      user: tempUser.user_id && tempUser.user_id > 0 ? true : false,
      customer: tempUser.user_id && tempUser.role !== "admin" ? true : false,
      company: tempUser.company,
      admin: tempUser.role === "admin",
      infoOnly: true,
    };
    console.log(
      `Current user is ${authType}? - ${permissions[authType] ? "Yes" : "No"}`
    );

    if (permissions[authType]) {
      authorized = true;
    }

    if (!authorized) {
      console.log("kick");
      kick();
      return;
    }

    tempUser = { ...tempUser, permissions };
    userContext.setCurrentUser(tempUser);

    // console.log('userContrext during',authType,'1', userContext.currentUser)
    return;
  };

  useEffect(() => {
    const asyncAuthorize = async () => {
      await authorizeUser();
    };
    asyncAuthorize();
  }, []);

  // console.log('userContrext during',authType,'2', userContext.currentUser)

  // return user;
  return userContext;
}

function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  let value = {
    currentUser,
    setCurrentUser,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
