import React, { useState } from "react";
import "./Login.scss";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { withRouter } from "react-router";
import { useUser } from "../../../UserContext";

import { Link } from "react-router-dom";

function Login(props) {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});
  const [validated, setValidated] = useState(false);
  let user = useUser();
  const login = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      axios
        .post("/api/auth/login", {
          ...state,
          email: state.email.replaceAll(" ", "").toLowerCase(),
        })
        .then(async (res) => {
          // await setPersistence(auth, browserLocalPersistence).then(() => {
          //   signInWithCustomToken(auth, res.data.token)
          //     .then((userCred) => {
          //       console.log("Logged in to Firebase.");
          //     })
          //     .catch((err) => console.log("firebase error", err));
          // });

          await user.setCurrentUser(res.data);
          if (res.data.role === "admin") {
            props.history.push("/admin");
          }

          // ! not tested
          if (res.data.user_id && !res.data.role) {
            props.history.push("/portal");
          }
          if (props.setLoggedIn) {
            props.setLoggedIn(true);
          }
          if (props.setExpanded) {
            props.setExpanded(false);
          }
          props.toggleModal(false);
        })

        .catch((err) => {
          console.log("err", err.response, err);
          setError(err.response);
        });
    }
  };

  const inputHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Row>
      <Col xs="12" className="mx-auto">
        <Container className="p-0">
          <Form
            noValidate={true}
            validated={validated}
            onSubmit={login}
            className="login-form mx-auto  p-4 rounded-3"
          >
            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="you@email.com"
                name="email"
                value={state.email}
                onChange={inputHandler}
              />
            </Form.Group>
            {error.status && error.status === 400 ? (
              <Alert variant="danger">{error.data}</Alert>
            ) : null}
            <Form.Group className="mb-2">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="********"
                required
                name="password"
                value={state.password}
                onChange={inputHandler}
              />
            </Form.Group>
            {error.status && error.status === 401 ? (
              <Alert variant="danger">{error.data}</Alert>
            ) : null}
            <Row>
              <Button
                className="my-2 mx-auto w-25"
                type="submit"
                variant="primary"
              >
                LOGIN
              </Button>
            </Row>
            <Row>
              <Link
                to="/password/forgot"
                onClick={() => props.toggleModal(false)}
              >
                <p className="text-primary text-center mt-2">
                  Forgot your password?
                </p>
              </Link>
              {props.match.path !== "/join" && (
                <Link
                  to="/join"
                  onClick={() => props.toggleModal(false)}
                  className="text-primary text-center mt-2"
                >
                  Sign up for an account.
                </Link>
              )}
            </Row>
          </Form>
        </Container>
      </Col>
    </Row>
  );
}

export default withRouter(Login);
