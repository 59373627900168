import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Customer from "./Customer";
import Milestone from "../../../ViewJob/Milestones/Milestone";
import MilestoneSection from "./MilestoneSection";
import Subcontractors from "./Subcontractors";
import Swal from "sweetalert2";
function NewJobForm() {
  const [state, setState] = useState({});
  const [services, setServices] = useState([]);
  const [creatingJob, setCreatingJob] = useState(false);
  useEffect(() => {
    loadFormData();
    // loadDefaultFeeRate()
    // if (props.primary) {;
    //   setPrimary({
    //     ...primary,
    //     name: props.primary.name,
    //     company_id: props.primary.company_id,
    //   });
    // }
  }, []);
  const [primary, setPrimary] = useState({
    company_id: 0,
    service: "",
    amt_bc_owes: "",
    job_id: 0,
    primary_company_name: "",
  });
  const [jobId, setJobId] = useState(0);
  const [subsDone, setSubsDone] = useState(false);
  const loadFormData = () => {
    axios.get("/api/forms/jobs/new-job-form").then((res) => {
      setServices(res.data?.services);
      setPrimary({ ...res.data?.formData });
    });
  };
  console.log("newjobformstate", state);
  const createJob = (addSub) => {
    if (!jobId || jobId < 1) {
      let body = { ...state, primary_name: primary.primary_company_name };
      if (body.alreadyExisted) {
        body.new_customer = false;
      }
      setCreatingJob(true);
      axios
        .post("/api/job/basic", body)
        .then((res) => {
          let newJobId = res.data?.job_id;
          setJobId(newJobId);
          if (addSub === "no") {
            setSubsDone(true);
          }
          setCreatingJob(false);
        })
        .catch((err) => {
          console.log("error creating job", err);
          Swal.fire({icon: 'error', title: "Error Creating Project",text: "Please reload the page and try again.", timer: 5000, })
          
        });
    } else {
      setSubsDone(true);
      setCreatingJob(false)
    }
  };
  return (
    <div>
      <Container>
        <h2 className="text-center mt-3 ">
          New Project for
          <em className="text-primary"> {primary.primary_company_name}.</em>
        </h2>
        <Row className="mt-5">
          {/* <h3 className="m-0">General Contractor</h3> */}

          {/* <hr /> */}
          <Form.Group as={Col} xs="7" lg="6" className="mt-1 align-self-end">
            <Form.Label className="m-0 h4">Main Service Provided</Form.Label>
            <Form.Select
              value={state.service}
              onChange={(e) => {
                setState({ ...state, service: e.target.value });
                setPrimary({ ...primary, service: e.target.value });
              }}
              name="service"
              required
            >
              <option value="">--</option>

              {services[0] &&
                services
                  .sort((a, b) => a.service_name.localeCompare(b.service_name))
                  .map((s) => {
                    return (
                      <option key={s.service_name} value={s.service_name}>
                        {s.service_name}
                      </option>
                    );
                  })}
            </Form.Select>
          </Form.Group>
        </Row>
        <hr />
        <Customer
          setState={setState}
          primary={primary}
          disabled={!state.service}
          state={state}
        />
        <hr />
        <Subcontractors
          disabled={!state.service || !state.customer_id}
          jobId={jobId}
          createJob={createJob}
          services={services}
          subsDone={subsDone}
          // disabled={false}
          state={state}
          setState={setState}
        />
        <hr />
        {creatingJob && (
          <Container className="main-content d-flex justify-content-center">
                      <Spinner animation="grow" />
                    </Container>
        )}
        <MilestoneSection
          disabled={
            jobId < 1 || !state.service || !state.customer_id || !subsDone
          }
          jobId={jobId}
          setState={setState}
          state={state}
        />
      </Container>
    </div>
  );
}

export default NewJobForm;
