import React from "react";
import "./Join.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
function Join(props) {
  return (
    <Container
      className="d-flex flex-column justify-content-around p-4 mb-3"
      id="join-container"
      // style={{ backgroundImage: "url('/images/handshake.jpg')" }}
    >
      <Row>
        <Col
          xs="12"
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h3 className="text-light text-center mb-3">
            JOIN BRIDGE CORE
          </h3>
          <h5 className="text-center text-light">
            <em>Become a Bridge Core Member Contractor.</em>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="d-flex justify-content-center">
          <Button variant="light" onClick={() => props.history.push('/join')}>Become a Member</Button>
        </Col>
      </Row>
    </Container>

  );
}

export default withRouter(Join);
